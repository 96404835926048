<template>
  <div>
    <div class="booking-ticket__download" id="booking-ticket">
      <div class="booking-ticket__download__wrapper">
        <div class="bt__header-bg"></div>
        <div class="bt__header">
          <!-- <img src="@/assets/rishikesh-app.png" alt="Rishikesh.app logo" class="bt__logo" /> -->
          <img :src="`https://rishikesh.app/ri/logo/logo-v2.svg`" alt="Rishikesh.app logo" class="bt__logo" />
          <div class="bt__header__text">
            <div class="--title">RISHIKESH.APP</div>
            <div class="--desc">Experience Culture With Endless Venture</div>
          </div>
        </div>
        <div class="bt__body">
          <div class="bt__cards">
            <div class="bt__card">
              <div class="booking-name">
                <div style="display: flex; align-items: center; gap: 4px">
                  <Icon icon="fluent:person-20-regular" size="20" />
                  <div style="font-weight: bold; text-transform: uppercase; color: rgba(0, 0, 0, 0.6)">{{ bookingData.primaryGuestName }}</div>
                </div>
                <Icon icon="ph:seal-check-duotone" size="20" style="color: #008080" />
              </div>
              <div class="booking-id">
                <div style="font-weight: 600">Booking Id:</div>
                <div>{{ bookingData.bookingId }}</div>
              </div>
              <div class="ticket-info">
                <div class="ticket-block --left">
                  <div style="font-weight: 600; font-size: 14px">{{ bookingData.unitCountBooked }}</div>
                  <div style="font-size: 11px">Tickets</div>
                </div>
                <div class="ticket-block --right">
                  <div style="font-weight: 600; font-size: 14px">
                    {{ bookingData.activityName }} <span style="text-transform: initial; font-weight: 300">({{ bookingData.activityVariant }})</span>
                  </div>
                  <div style="font-size: 11px">{{ new Date(bookingData.activityDate).toDateString() }} - {{ bookingData.activitySlot }}</div>
                </div>
                <div class="ticket-overlay"></div>
              </div>
            </div>
            <div class="bt__card">
              <div class="provider-block">
                <div>Provider Details</div>
                <!-- <Icon icon="system-uicons:external" size="20" class="provider-link" /> -->
              </div>
              <div class="provider-info__wrapper">
                <img :src="bookingData.providerLogo" :alt="`${bookingData.providerName} logo`" class="bt__provider-logo" v-if="bookingData.providerLogo" />
                <img src="https://rishikesh.app/ri/activities/icons/rafting-black.svg" :alt="`${bookingData.providerName} logo`" class="bt__provider-logo" v-else-if="bookingData.activitySlug === 'river-rafting'" />
                <div class="block-seprator"></div>
                <div class="provider-info">
                  <div class="provider-header">{{ bookingData.providerName }}</div>
                  <div class="provider-desc">
                    <Icon icon="ion:call-outline" size="16" />
                    <div>+91-{{ bookingData.providerContact || 8006888600 }}</div>
                  </div>
                  <div class="provider-desc">
                    <Icon icon="system-uicons:location" size="16" />
                    <div>{{ bookingData.providerAddress }}, {{ bookingData.providerCity }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bt__card">
              <div class="payment-block">
                <div class="payment-header">Payment Details</div>
                <!-- <div class="payment-status">
                  <Icon icon="clarity:error-line" size="12" />
                  <div style="font-size: 10px">{{ bookingData.bookingStatus }}</div>
                </div> -->
              </div>
              <div class="payment-details__wrapper">
                <img :src="qrCodeUrl" alt="QR Code" class="bt__qr-code" />
                <div class="block-seprator"></div>
                <div class="payment-details">
                  <!-- <div class="payment-details__row">
                    <div>Unit Price:</div>
                    <MoneyFormat :amount="bookingData.totalPrice / bookingData.numberOfPeople" />
                  </div>
                  <div class="payment-details__row">
                    <div>No. of person</div>
                    <div>x{{ bookingData.numberOfPeople }}</div>
                  </div> -->
                  <div class="payment-details__row">
                    <div class="payment-details__row__left">
                      <Icon icon="material-symbols-light:currency-rupee" size="11" color="#008080" />
                      <div>Advance Paid:</div>
                    </div>
                    <MoneyFormat :amount="bookingData.totalAdvancePay" />
                  </div>
                  <div class="payment-details__row">
                    <div class="payment-details__row__left">
                      <Icon icon="material-symbols-light:currency-rupee" size="11" color="#cf3917" />
                      <div>Remaining:</div>
                    </div>
                    <MoneyFormat :amount="bookingData.totalSellingPrice - bookingData.totalDiscount - bookingData.totalAdvancePay" />
                  </div>
                  <div class="payment-details__row --highlighted">
                    <div class="payment-details__row__left">
                      <Icon icon="ion:pricetags-outline" size="11" />
                      <div>Grand Total:</div>
                    </div>
                    <div v-if="bookingData.totalDiscount" style="display: flex; align-items: center; gap: 4px">
                      <MoneyFormat strike style="font-weight: bold" :amount="bookingData.totalSellingPrice" />
                      <MoneyFormat :amount="bookingData.totalSellingPrice - bookingData.totalDiscount" />
                    </div>
                    <MoneyFormat v-else :amount="bookingData.totalSellingPrice" />
                  </div>
                  <!-- <div class="payment-details__row">
                    <div>Advanced paid:</div>
                    <MoneyFormat amount="0" />
                  </div>
                  <div class="payment-details__row">
                    <div>Amount Due:</div>
                    <MoneyFormat :amount="calculateAmountDue" />
                  </div> -->
                </div>
                <div class="ticket-overlay"></div>
              </div>
            </div>
            <div class="bt__card--bottom">
              <div class="bt__card__bottom-buttons">
                <a v-if="bookingData.providerLocation" class="cta-button" :href="getproviderDirectionsUrl(bookingData.providerLocation)">
                  <Icon icon="logos:google-maps" size="16" />
                  <div>Get Direction</div>
                </a>
                <a href="https://www.instagram.com/rishikesh.app/" class="cta-button">
                  <Icon icon="skill-icons:instagram" size="16" />
                  <div>@rishikesh.app</div>
                </a>
              </div>
            </div>
            <div class="bottom-tagline">Travel far enough, you'll meet yourself 🤟🏻</div>
          </div>
        </div>
        <div class="bt__footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import MoneyFormat from '@/components/common/MoneyFormat.vue';
import QRCode from 'qrcode';

export default {
  data() {
    return {
      qrCodeUrl: '',
    };
  },
  props: ['bookingData'],
  components: {
    Icon,
    MoneyFormat,
  },

  computed: {
    calculateAmountDue() {
      return this.bookingData.totalPrice - 0;
    },
  },
  created() {
    this.generateQRCode();
  },
  methods: {
    getproviderDirectionsUrl(providerLocation) {
      if (providerLocation.direct) {
        return `https://www.google.com/maps/dir/?api=1&destination=${providerLocation.direct}`;
      } else {
        return `https://www.google.com/maps/dir/?api=1&destination=${providerLocation.lat},${providerLocation.lon}`;
      }
    },
    generateQRCode() {
      QRCode.toDataURL(this.bookingData.bookingId, {
        height: 64,
        width: 64,
      })
        .then(url => {
          this.qrCodeUrl = url;
        })
        .catch(err => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
.booking-ticket__download {
  .booking-ticket__download__wrapper {
    // padding: 16px;
    // margin: 0 auto;
    max-width: 480px;
    margin: auto;
    background-color: #f4f4f4;
    position: relative;
  }
  .bt__header-bg {
    background: radial-gradient(100% 100% at 50% 100%, #1c8a8a 0%, rgba(5, 41, 41, 0.84) 100%);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 120px;
  }
  .bt__header {
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px 8px 16px 0px;
    position: relative;
  }
  .bt__logo {
    height: 48px;
  }
  .bt__header__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #deebf7;
    .--title {
      font-family: 'KoHo';
      font-size: 28px;
      line-height: 32px;
      //   text-transform: uppercase;
    }
    .--desc {
      font-size: 9px;
      text-transform: uppercase;
    }
  }
  .bt__body {
    padding: 0px 16px;
    position: relative;
  }
  .bt__cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .bt__card {
    padding: 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
  }
  .bt__card--bottom {
    background-color: transparent;
    padding: 0px;
    font-size: 12px;
  }
  .booking-name {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }
  .booking-id {
    background-color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
  }
  .ticket-info {
    display: flex;
    position: relative;
    padding-top: 8px;
    .ticket-block {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &.--left {
        border-right: 1px dashed rgba(0, 0, 0, 0.2);
        padding-right: 16px;
        align-items: center;
        text-transform: uppercase;
        min-width: 72px;
      }
      &.--right {
        padding-left: 16px;
        align-items: flex-start;
        text-transform: uppercase;
      }
    }
    .ticket-overlay {
      position: absolute;
      top: 0;
      height: 40px;
      width: 100%;
    }
  }
  .provider-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    padding-bottom: 8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    .provider-link {
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .provider-info__wrapper {
    display: flex;
    gap: 8px;
    .provider-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 8px;
      padding-top: 8px;
      // border-left: 1px dashed rgba(0, 0, 0, 0.2);
    }
    .provider-header {
      font-size: 14px;
      padding-bottom: 4px;
      font-weight: 600;
      color: rgba(20, 58, 86, 0.9);
    }
    .provider-desc {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 12px;
      opacity: 0.7;
    }
  }
  .block-seprator {
    width: 1px;
    border-right: 1px dashed rgba(0, 0, 0, 0.2);
    margin-top: 8px;
  }
  .payment-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    padding-bottom: 8px;
    .payment-header {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }
    .payment-status {
      display: flex;
      align-items: center;
      gap: 2px;
      color: #db5c33;
      font-style: italic;
    }
  }
  .payment-details__wrapper {
    display: flex;
    gap: 8px;
    position: relative;

    .payment-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 8px;
      padding-top: 8px;
      font-size: 12px;
      gap: 4px;
      flex: 1 1 0;
    }
    .payment-details__row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      opacity: 0.7;
      font-size: 11px;
      &.--highlighted {
        font-weight: 600;
        opacity: 1;
        padding: 4px;
        background-color: #f4f4f4;
        border-radius: 4px;
      }
    }
    .ticket-overlay {
      position: absolute;
      top: 0;
      height: 60px;
      width: 100%;
    }
    .payment-details__row__left {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .bt__provider-logo {
    width: 64px;
    height: 64px;
    object-fit: contain;
    background-color: #ffffff;
  }
  .bt__qr-code {
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
  .bt__card__bottom-buttons {
    display: flex;
    justify-content: space-between;
    .cta-button {
      display: flex;
      gap: 8px;
      font-size: 12px;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #ffffff;
      &:hover {
        background-color: #f5f9fb;
      }
    }
  }

  .bottom-tagline {
    padding: 0px 16px 16px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    opacity: 0.6;
  }
  .bt__footer {
    background: #008080;
    height: 8px;
  }
}
</style>
