<template>
  <div class="price-display" :class="{ '--strike-out': strike, '--bold': bold }"><span class="currency-font">&#8377;</span>{{ parseInt(amount).toLocaleString() }}/-</div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
    },
    strike: {
      type: Boolean,
    },
    bold: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.currency-font {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.price-display {
  display: inline-block;
  &.--bold {
    font-weight: 600;
  }
  &.--strike-out {
    position: relative;
    opacity: 0.8;
    &::before {
      content: '';
      width: 100%;
      position: absolute;
      right: 0;
      top: 50%;
      border-bottom: 0.8px solid currentColor;
      transform: rotate(-8deg);
    }
  }
}
</style>
