<template>
  <div class="app-header">
    <div class="header-items container">
      <div class="header__logo-wrapper">
        <div>
          <svg
            width="34"
            height="34"
            viewBox="0 0 352 352"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M176 352C130.644 352 87.8584 345.95 55.5239 334.965C9.63325 319.373 0 299.403 0 285.396C0 267.123 16.3274 254.317 30.0238 246.793C35.0171 244.051 41.2851 245.875 44.0275 250.866C46.7699 255.858 44.9467 262.128 39.9548 264.87C27.6705 271.618 20.625 279.1 20.625 285.396C20.625 293.096 31.5356 305.031 62.1589 315.435C92.4124 325.715 132.842 331.375 176 331.375C219.158 331.375 259.588 325.715 289.841 315.435C320.464 305.032 331.375 293.096 331.375 285.396C331.375 279.1 324.33 271.618 312.045 264.869C307.053 262.127 305.23 255.857 307.972 250.865C310.714 245.874 316.983 244.05 321.976 246.793C335.673 254.317 352 267.123 352 285.396C352 299.403 342.367 319.373 296.476 334.965C264.142 345.95 221.356 352 176 352Z"
              fill="#16B2AE"
              fill-opacity="0.3"
            />
            <path
              d="M321.976 246.793C316.983 244.051 310.714 245.875 307.973 250.866C305.23 255.858 307.053 262.128 312.045 264.87C324.33 271.618 331.375 279.1 331.375 285.396C331.375 293.097 320.464 305.032 289.841 315.435C259.588 325.715 219.158 331.375 176 331.375C175.999 331.375 175.998 331.375 175.997 331.375V352C175.998 352 175.999 352 176 352C221.356 352 264.142 345.95 296.476 334.965C342.367 319.373 352 299.403 352 285.396C352 267.123 335.673 254.317 321.976 246.793Z"
              fill="#008080"
              fill-opacity="0.3"
            />
            <path
              d="M176 309.698C146.727 309.698 119.076 306.442 98.1421 300.527C72.5306 293.293 59.5444 282.819 59.5444 269.401C59.5444 255.981 72.5306 245.509 98.1421 238.275C119.076 232.36 146.727 229.104 176 229.104C205.273 229.104 232.924 232.36 253.858 238.275C279.469 245.509 292.456 255.982 292.456 269.401C292.456 282.819 279.469 293.293 253.858 300.527C232.924 306.441 205.273 309.698 176 309.698ZM80.3441 269.4C81.7596 271.478 88.6449 276.883 107.493 281.683C126.202 286.449 150.532 289.073 175.999 289.073C201.466 289.073 225.797 286.449 244.506 281.683C263.354 276.883 270.24 271.478 271.655 269.4C270.239 267.322 263.354 261.918 244.505 257.117C225.796 252.352 201.467 249.728 175.999 249.728C150.532 249.728 126.202 252.352 107.493 257.117C88.6456 261.919 81.7603 267.322 80.3441 269.4ZM271.891 269.827H271.899H271.891Z"
              fill="#16B2AE"
              fill-opacity="0.3"
            />
            <path
              d="M253.858 238.274C232.924 232.359 205.273 229.103 176 229.103C175.999 229.103 175.998 229.103 175.997 229.103V249.728C175.998 249.728 175.999 249.728 176 249.728C201.468 249.728 225.797 252.352 244.506 257.117C263.355 261.918 270.241 267.322 271.656 269.4C270.24 271.478 263.355 276.882 244.507 281.683C225.798 286.448 201.468 289.072 176 289.072C175.999 289.072 175.998 289.072 175.997 289.072V309.697C175.998 309.697 175.999 309.697 176 309.697C205.273 309.697 232.924 306.441 253.858 300.526C279.47 293.292 292.456 282.818 292.456 269.4C292.456 255.981 279.47 245.508 253.858 238.274Z"
              fill="#008080"
              fill-opacity="0.3"
            />
            <path
              d="M176 0C123.14 0 80.135 43.0045 80.135 95.8643C80.135 128.789 120.136 201.146 153.692 256.048C158.481 263.884 166.821 268.561 176 268.561C185.18 268.561 193.519 263.883 198.308 256.048C231.865 201.146 271.865 128.789 271.865 95.8643C271.865 43.0045 228.861 0 176 0ZM176 127.814C154.754 127.814 137.468 110.529 137.468 89.2822C137.468 68.0364 154.754 50.7512 176 50.7512C197.247 50.7512 214.532 68.0364 214.532 89.2822C214.532 110.529 197.247 127.814 176 127.814Z"
              fill="#16B2AE"
            />
            <path
              d="M176 0C175.999 0 175.998 0 175.997 0V50.7512C175.998 50.7512 175.999 50.7512 176 50.7512C197.247 50.7512 214.532 68.0364 214.532 89.2822C214.532 110.529 197.247 127.814 176 127.814C175.999 127.814 175.998 127.814 175.997 127.814V268.561C175.998 268.561 175.999 268.561 176 268.561C185.18 268.561 193.519 263.883 198.308 256.048C231.865 201.146 271.865 128.789 271.865 95.8643C271.865 43.0045 228.861 0 176 0Z"
              fill="#008080"
            />
          </svg>
        </div>
      </div>
      <div class="search-row">
      </div>
      <div class="social__row">
        <div class="header-social-icons__container">
          <a href="https://www.instagram.com/rishikesh.app/" target="_blank" class="social-links">
            <i class="header-social light-icon-brand-instagram"></i>
          </a>
          <a href="https://www.facebook.com/RISHIKESHapp" target="_blank" class="social-links">
            <i class="header-social light-icon-brand-facebook"></i>
          </a>
          <!-- <div class="social-links">
            <i class="header-social light-icon-brand-whatsapp"></i>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1:'',
    };
  },
};
</script>

<style scoped lang="scss">
.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.app-header {
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 97;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: -4px 4px 6px -4px rgba(0, 0, 0, 0.15);
  padding: 0px 16px;
}
.header-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: space-between;
}

.header-items .menu-icon {
  // color: #333333;
  font-size: 30px;
  // font-weight: bolder;
  padding: 10px;
  border-radius: 50%;
  transition: 0.3s ease-in-out all;
  &:hover {
    background: #e0e0e0;
  }
}
.header__logo-row {
  display: flex;
}
.menu-icon {
  display: none;
}
.header-logo {
  width: 50px;
}
.search-row {
  display: flex;
  margin: auto;
  padding: 40px 0;
}
.search-bar {
  position: relative;
  margin: 0 auto;
  width: 360px;
  display: flex;
  position: absolute;
  left: calc(50% - 180px);
  justify-content: center;
  z-index: 100;
  transition: all 0.5s;
}

.social-links {
  color: inherit;
  text-decoration: none;
}
.social__row {
  .header-social-icons__container {
    display: flex;
    align-items: center;
    .header-social {
      font-size: 24px;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: black;
        padding: 10px;
        background: #dfdfdf;
      }
      &.profile-photo {
        width: 48px;
        height: 48px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 768px) {
  .app-header {
    padding-left: 10px;
  }
  .header-items {
    margin: 0;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding-left: 0px;
  }
  .menu-icon {
    display: inline-block;
    margin-right: 12px;
    cursor: pointer;
  }
  .header-social-icons__container,
  .logo-text {
    display: none;
  }
  .search-bar {
    width: 250px;
  }
}
.header__logo-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
