<template>
  <div>
    <div v-if="isLoading">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#38b2ac" />
    </div>
    <div v-else>
      <BookingTicketStatus :booking-data="bookingData" />
      <div class="booking-ticket__button">
        <LvButton label="Download Ticket" @click="exportToPDF()" class="lv--success" push />
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/config/index.ts';
import BookingTicketStatus from '@/components/status/BookingTicketStatus.vue';
import Loading from 'vue-loading-overlay';
import html2pdf from 'html2pdf.js';
import LvButton from 'lightvue/button';

export default {
  components: {
    BookingTicketStatus,
    Loading,
    LvButton,
  },
  data() {
    return {
      isLoading: false,
      bookingData: null,
    };
  },
  created() {
    this.getbookingData();
  },

  methods: {
    async getbookingData() {
      this.isLoading = true;
      try {
        this.$router.push({ path: '', query: { tid: 'NjQ4MmJkYzhmOTBlMmZlMzVjZDIxZjZjLTc4OTg0NTEyMzE=' } });
        const storedData = await fetch(`${appConfig.API_BASE_URL}/v1/booking/ticket/NjQ4MmJkYzhmOTBlMmZlMzVjZDIxZjZjLTc4OTg0NTEyMzE=`)
          .then(response => response.json())
          .then(data => {
            return data;
          });
        this.bookingData = {
          name: storedData.Booking.customer.name,
          activitySlug: storedData.Booking.activity._slug,
          activityName: storedData.Booking.activity.activity_name,
          activityVariant: storedData.Booking.activity.activity_variant,
          activitySlot: storedData.Booking.activity_slot,
          activityDate: storedData.Booking.activity_date_iso,
          numberOfPeople: storedData.Booking.no_of_people,
          vendorName: storedData.Booking.vendor.vendor_name,
          bookingDate: storedData.Booking.createdAt,
          bookingStatus: storedData.Booking.booking_status,
          vendorAddress: storedData.Booking.vendor.address.address_line_2,
          vendorCity: storedData.Booking.vendor.address.city,
          vendorContact: storedData.Booking.vendor.phone_number,
          vendorLogo: storedData.Booking.vendor.vendor_logo,
          totalPrice: storedData.Booking.total_price,
          bookingId: storedData.Booking.booking_id,
          vendorLocation: storedData.Booking.vendor.vendor_location,
        };
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    exportToPDF() {
      var element = document.getElementById('booking-ticket');
      var opt = {
        filename: this.bookingData.bookingId,
        margin: 0,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { orientation: 'p', unit: 'px', format: [320, 660], putOnlyUsedFonts: true },
      };
      html2pdf(element, opt);
    },
  },
};
</script>

<style lang="scss">
.booking-ticket__button {
  padding: 12px 0px;
  max-width: 480px;
  margin: auto;
  .lv-button {
    width: 100%;
  }
}
</style>
