<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import url('@/assets/styles/scss/global.scss');
@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@500;600&family=Roboto:wght@100;300;400;500;700;900&display=swap');
</style>
