import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';

export const displayedFormattedNumber = phoneNumber => {
  let displayNumber = parsePhoneNumber(phoneNumber);
  const formattedPhoneNumberObj = parsePhoneNumberFromString(phoneNumber);
  if (formattedPhoneNumberObj.country === 'IN') {
    return displayNumber.formatInternational();
  } else {
    return `+${formattedPhoneNumberObj.countryCallingCode} ${displayNumber.formatNational()}`;
  }
};
