import { createRouter, createWebHistory } from 'vue-router';
import TicketMain from '@/components/TicketMain';
// import PaymentSuccess from '@/components/Payment/PaymentSuccess.vue';
// import PaymentFailed from './components/Payment/PaymentFailed.vue';
// import BookingTicket from '@/components/Ticket/BookingTicket.vue';
// import BookingTicketDownload from '@/components/status/BookingTicketDownload.vue';
import TicketStatusView from '@/views/TicketStatusView.vue';
const routes = [
  {
    path: '/',
    beforeEnter() {
      location.href = 'https://rishikesh.app';
    },
  },
  { path: '/check', component: TicketMain },
  // { path: '/ticket', component: BookingTicket },
  { path: '/status', component: TicketStatusView },
  // { path: '/success', component: PaymentSuccess },
  // { path: '/failed', component: PaymentFailed },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
